body {
  margin: 0;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(229, 230, 231);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bold{
  font-weight: bold;
}
.grid-center{
  display: grid;
  place-items: center;
}
.flex-row{
  display: flex;
  align-items: center;
  flex-direction: row;
}
.flex-col{
  display: flex;
  flex-direction: column;
}
.j-center{
  justify-content: center;
}
.j-space{
  justify-content: space-between;
}
.primary{
  color: rgb(85, 26, 245);
}
.secondary{
  background-color: rgb(85, 26, 245);
}
.margin-0{
  margin: 0;
}
.padding-0{
  padding: 0;
}
.padding-sm{
  padding: 5px;
}
.margin-sm{
  margin: 5px;
}
.bold{
  font-weight: bold;
}
.text-dec-none{
  text-decoration: none;
}
.nav-a{
  color: rgb(65, 39, 136);
}
.active{
  color: rgb(50, 98, 230);
}
.main{
  margin-top: 150px;
}
.text-center{
  text-align: center;
}
.navs{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.blur-primary{
  background-color: rgba(85, 26, 245, 0.2)
}
.footer{
  background-color: rgb(44, 43, 43);
}
.items-container {
  display: flex;
  flex-direction: column;
  transition: opacity 0.5s ease-in-out;
}

.item {
  margin: 20px;
  padding: 20px;
  color: white;
  border-radius: 8px;
  opacity: 0; /* Start hidden */
  transform: translateY(20px); /* Start slightly below */
  transition: opacity 0.5s ease, transform 0.5s ease; /* Transition properties */
}

.items-container.animate .item {
  opacity: 1; /* Fade in */
  transform: translateY(0); /* Move to original position */
}
.outline-none{
  outline: none;
}
.input-block{
  display: block;
}
.order-top{
  border-radius: 5px 5px 0px 0px;
}